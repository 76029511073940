import ApiService from "@/core/services/api.service";

/**
 * GET request to fetch all data
 * @returns {*}
 */
export const getAllSubjectTypes = () => {
  return ApiService.get("subject_types");
};

/**
 * GET request to fetch a specific data
 * @param id
 * @returns {*}
 */
export const getSubjectType = (id) => {
  return ApiService.get("subject_types", id);
};

/**
 * POST request to create a new subject_type
 * @param payload
 * @returns {*}
 */
export const createSubjectType = (payload) => {
  return ApiService.post("subject_types", payload);
};

/**
 * PUT request to update a specific subject_type
 * @param id
 * @param payload
 * @returns {*}
 */
export const updateSubjectType = (id, payload) => {
  return ApiService.update("subject_types", id, payload);
};

/**
 * DELETE request to delete the specified subject_type
 * @param id
 * @returns {*}
 */
export const deleteSubjectType = (id) => {
  return ApiService.delete(`subject_types/${id}`);
};

/**
 * GET request to change status of the specified subject_type
 * @param id
 * @returns {*}
 */
export const changeStatusSubjectType = (id) => {
  return ApiService.get("subject_types/status", id);
};

export default {
  getAllSubjectTypes,
  getSubjectType,
  createSubjectType,
  updateSubjectType,
  deleteSubjectType,
  changeStatusSubjectType,
};
