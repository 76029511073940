import ApiService from "@/core/services/api.service";

/**
 * GET request to fetch all data
 * @returns {*}
 */
export const getAllActivityCategories = () => {
  return ApiService.get("activity-categories");
};

/**
 * GET request to fetch activity categories by subject type
 * @returns {*}
 */
export const getActivityCategoriesBySubjectType = (subjectTypeId) => {
  return ApiService.get("activity-categories/subject-type", subjectTypeId);
};

/**
 * GET request to fetch activity categories by subject type
 * @returns {*}
 */
export const getActivityCategoriesBySubjectTypeAndStageId = (subjectTypeId, stageId) => {
  return ApiService.get(`activity-categories/subject-type/${subjectTypeId}/stage/${stageId}`);
};

/**
 * GET request to fetch a specific data
 * @param id
 * @returns {*}
 */
export const getActivityCategory = (id) => {
  return ApiService.get("activity-categories", id);
};

/**
 * POST request to create a new activity category
 * @param payload
 * @returns {*}
 */
export const createActivityCategory = (payload) => {
  return ApiService.post("activity-categories", payload);
};

/**
 * PUT request to update a specific activity category
 * @param id
 * @param payload
 * @returns {*}
 */
export const updateActivityCategory = (id, payload) => {
  return ApiService.update("activity-categories", id, payload);
};

/**
 * DELETE request to delete the specified activity category
 * @param id
 * @returns {*}
 */
export const deleteActivityCategory = (id) => {
  return ApiService.delete(`activity-categories/${id}`);
};

/**
 * GET request to change status of the specified activity category
 * @param id
 * @returns {*}
 */
export const changeStatusActivityCategory = (id) => {
  return ApiService.get("activity-categories/status", id);
};

export default {
  getAllActivityCategories,
  getActivityCategoriesBySubjectType,
  getActivityCategory,
  createActivityCategory,
  updateActivityCategory,
  deleteActivityCategory,
  changeStatusActivityCategory,
  getActivityCategoriesBySubjectTypeAndStageId,
};
