import ApiService from "@/core/services/api.service";

/**
 * GET request to fetch all data
 * @returns {*}
 */
export const getAllActivityTypes = () => {
  return ApiService.get("activity_types");
};

/**
 * GET request to fetch activity types by activity category
 * @returns {*}
 */
export const getActivityTypesByActivityCategory = (activityCategoryId) => {
  return ApiService.get("activity_types/activity_category", activityCategoryId);
};

/**
 * GET request to fetch a specific data
 * @param id
 * @returns {*}
 */
export const getActivityType = (id) => {
  return ApiService.get("activity_types", id);
};

/**
 * POST request to create a new activity_type
 * @param payload
 * @returns {*}
 */
export const createActivityType = (payload) => {
  return ApiService.post("activity_types", payload);
};

/**
 * PUT request to update a specific activity_type
 * @param id
 * @param payload
 * @returns {*}
 */
export const updateActivityType = (id, payload) => {
  return ApiService.update("activity_types", id, payload);
};

/**
 * DELETE request to delete the specified activity_type
 * @param id
 * @returns {*}
 */
export const deleteActivityType = (id) => {
  return ApiService.delete(`activity_types/${id}`);
};

/**
 * GET request to change status of the specified activity_type
 * @param id
 * @returns {*}
 */
export const changeStatusActivityType = (id) => {
  return ApiService.get("activity_types/status", id);
};

export default {
  getAllActivityTypes,
  getActivityTypesByActivityCategory,
  getActivityType,
  createActivityType,
  updateActivityType,
  deleteActivityType,
  changeStatusActivityType,
};
