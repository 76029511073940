<template>
  <v-dialog
    v-model="dialog"
    scrollable
    transition="dialog-bottom-transition"
    max-width="675"
    persistent
  >
    <v-form>
      <v-card>
        <v-toolbar dark color="amber" class="elevation-0 px-3">
          <span class="headline">Actualizar agrupación de perfil</span>
        </v-toolbar>
        <v-card-text>
          <v-container v-if="activityCategory">
            <v-row>
              <v-col cols="12" md="6" sm="6" class="pb-0">
                <form-group
                  name="nombre de la agrupación de perfil"
                  :validator="$v.activityCategory.name"
                >
                  <v-text-field
                    slot-scope="{ attrs }"
                    v-bind="attrs"
                    label="Agrupación de perfil"
                    placeholder="Ingresa el nombre de la agrupación de perfil"
                    required
                    outlined
                    v-model="activityCategory.name"
                  />
                </form-group>
              </v-col>
              <v-col col="12" sm="6" class="pb-0">
                <form-group
                  name="tipo de asignatura"
                  :validator="$v.activityCategory.subject_type_id"
                >
                  <v-select
                    slot-scope="{ attrs }"
                    v-bind="attrs"
                    label="Tipo de asignatura"
                    placeholder="Selecciona un tipo de asignatura"
                    :items="subjectTypes"
                    item-text="name"
                    item-value="id"
                    required
                    outlined
                    v-model="activityCategory.subject_type_id"
                  ></v-select>
                </form-group>
              </v-col>
            </v-row>
            <v-row>
              <v-col col="12" sm="6" class="pb-0">
                <form-group
                  name="número de perfiles"
                  :validator="$v.activityCategory.number_evaluations"
                >
                  <v-text-field
                    slot-scope="{ attrs }"
                    v-bind="attrs"
                    label="Número de perfiles"
                    required
                    outlined
                    type="number"
                    v-model="activityCategory.number_evaluations"
                  />
                </form-group>
              </v-col>
              <v-col col="12" sm="6" class="pb-0">
                <form-group
                  name="porcentaje"
                  :validator="$v.activityCategory.percentage"
                >
                  <v-text-field
                    slot-scope="{ attrs }"
                    v-bind="attrs"
                    label="Porcentaje"
                    required
                    outlined
                    type="number"
                    prepend-inner-icon="mdi-percent"
                    v-model="activityCategory.percentage"
                  />
                </form-group>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <h3 class="text-h5">Tipos de actividades</h3>
                <span class="text-body-1 black--text"
                  >Selecciona los tipos de actividades que estarán disponibles
                  en la agrupación
                </span>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-chip-group
                  v-model="activityCategory.activity_type_ids"
                  column
                  multiple
                  active-class="black--text"
                >
                  <v-chip
                    filter
                    outlined
                    v-for="activityType in activityTypes"
                    :key="activityType.id"
                    :value="activityType.id"
                    @click:close="removeActivityType(activityTypeId)"
                  >
                    {{ activityType.name }}
                  </v-chip>
                </v-chip-group>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <h3 class="text-h5">Etapas</h3>
                <span class="text-body-1 black--text"
                  >Selecciona los etapas en las que estará disponible esta agrupación
                </span>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-chip-group
                  v-model="activityCategory.stage_ids"
                  column
                  multiple
                  active-class="black--text"
                >
                  <v-chip
                    filter
                    outlined
                    v-for="stage in stages"
                    :key="stage.id"
                    :value="stage.id"
                    @click:close="removeStage(stageId)"
                  >
                    {{ stage.name }}
                  </v-chip>
                </v-chip-group>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="justify-end pt-0">
          <v-btn text outlined class="mr-1" @click="closeModal">Cerrar</v-btn>
          <v-btn
            color="amber"
            dark
            depressed
            :loading="isLoading"
            @click="submit"
            >Actualizar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import {
  required,
  maxLength,
  minValue,
  maxValue,
} from "vuelidate/lib/validators";
import activityCategoryRepository from "@/repositories/activityCategoryRepository";

export default {
  name: "UpdateActivityCategoryModal",

  data() {
    return {
      isLoading: false,
      dialog: false,
    };
  },
  props: {
    activityCategory: {
      type: Object,
    },
    activityTypes: {
      type: Array,
    },
    subjectTypes: {
      type: Array,
    },
    stages: {
      type: Array,
    }
  },
  methods: {
    update() {
      this.isLoading = true;
      activityCategoryRepository
        .updateActivityCategory(this.activityCategory.id, this.activityCategory)
        .then(({ data }) => {
          // Showing alert with response data
          this.sweetAlertResponse(data);
          // Retrieving data, hidding modal and cleaning selection if operation has been completed
          if (data.status) {
            this.$emit("reload-data");
            this.$emit("clean-selection");
            this.closeModal();
          }
        })
        .catch(() => {
          this.fireToast({
            icon: "error",
            title: "No ha sido posible actualizar el registro 🙁",
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.fireToast({
          icon: "error",
          title: "Asegurate de llenar el formulario correctamente",
        });
        return;
      }
      this.update();
    },

    toggleModal() {
      this.dialog = !this.dialog;
    },

    closeModal() {
      this.toggleModal();
      this.$v.$reset();
    },

    removeActivityType(activityTypeId) {
      const index = this.activityCategory.activity_type_ids.indexOf(
        activityTypeId
      );
      this.activityCategory.activity_type_ids.splice(index, 1);
    },

    removeStage(stageId) {
      const index = this.activityCategory.stages_id.indexOf(
        stageId
      );
      this.activityCategory.activity_type_ids.splice(index, 1);
    },
  },
  validations: {
    activityCategory: {
      name: {
        required,
        maxLength: maxLength(255),
      },
      subject_type_id: {
        required,
      },
      number_evaluations: {
        required,
        minValue: minValue(1),
      },
      percentage: {
        required,
        minValue: minValue(1),
        maxValue: maxValue(100),
      },
    },
  },
};
</script>
